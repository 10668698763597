<template>
  <v-container class="px-md-10 py-5 px-5" fluid>
    <v-row>
      <v-col>
        <v-col>
          <header class="text-center">
            <v-img
              src="../assets/XUMED.png"
              contain
              height="75px"
              width="100px"
            ></v-img>
          </header>
          <div class="pt-5">Información legal.</div>
          <div>Última actualización: 01/05/2024.</div>
          <section id="terminos">
            <h2 class="secondary--text py-5">Términos y Condiciones</h2>
            <p>GENERALIDADES</p>
            <p>
              Grupo Videlsa, S.R.L (en lo adelante ́ ́El Proveedor ́ ́ o ́ ́Grupo
              Videlsa,S.R.L.), sociedad comercial organizada de conformidad con
              las leyes de la República Dominicana, portadora del Registro
              Mercantil No.6339LV, con domicilio social en Carretera Duarte
              Vieja, municipio y provincia de La Vega, República Dominicana, es
              titular de {{domain}} (en lo adelante ‘Sitio Web’), dedicado a
              ofrecer la utilización de un software llamado xumed® destinado a
              la gestión clínica, consultas médicas, entre otros servicios.
            </p>
            <p>
              Los presentes términos de uso (en lo adelante ́ ́Términos y
              Condiciones ́ ́) son suscritos, de una parte, por Grupo Videlsa,
              S.R.L., entendida como la entidad que pone el Sitio Web a
              disposición de cualquier usuario de Internet y, de una segunda
              parte, por el Usuario (en lo adelante ́ ́El Usuario ́ ́), entendido
              como cualquier persona física o jurídica que acceda a El Sitio Web
              de manera libre y voluntaria, independientemente de que haga uso o
              no de los servicios que se ofrecen a través del mismo.
            </p>
            <p>
              En todo el sitio, los términos "nosotros", "nos" y "nuestro" se
              refieren en lo sucesivo a xumed®. El Proveedor ofrece esta página
              web, incluida toda la información, las herramientas y los
              servicios que se ponen en este sitio a disposición suya, el
              usuario, siempre y cuando acepte la totalidad de los términos,
              condiciones, políticas y avisos contemplados aquí.
            </p>
            <p>
              El propósito principal del Sitio Web es la adquisición de los
              servicios disponibles, los cuales se centran en la administración
              y supervisión de consultas médicas, programación de citas,
              seguimiento de historiales clínicos de pacientes y prescripciones
              médicas . Estos servicios implican gastos y requieren la
              aceptación de los términos de uso.
            </p>
            <p>
              Al visitar nuestro Sitio Web, usted interactúa con nuestro
              "Servicio" y reconoce como vinculantes los siguientes términos y
              condiciones (denominados en lo sucesivo "Términos de uso",
              "Términos"), incluidos aquellos términos y condiciones adicionales
              y las políticas que se mencionan aquí y/o disponibles por medio de
              hipervínculo. Estos Términos de uso se aplican a todos los
              usuarios del sitio, incluyendo de manera enunciativa mas no
              limitativa los usuarios que navegan y utilizan el sitio web. El
              acceso al Sitio Web no conlleva ningún costo.
            </p>
            <p>
              Lea estos Términos de uso detenidamente antes de acceder o
              utilizar nuestra página web. Al acceder o utilizar cualquier parte
              del sitio, usted acepta estos Términos de uso. Si no acepta la
              totalidad de los términos y condiciones de este acuerdo, no podrá
              acceder al sitio web ni utilizar ningún servicio. Si estos
              Términos de uso se considerasen una oferta, la aceptación se
              limita expresamente a los presentes Términos de uso.
            </p>
            <p>
              Las nuevas funciones o herramientas que se agreguen al Sitio Web
              actual también estarán sujetas a los Términos de uso. Puede
              revisar la versión más reciente de los Términos de uso en
              cualquier momento en esta página. Nos reservamos el derecho de
              actualizar, cambiar o reemplazar cualquier parte de los presentes
              Términos de uso mediante la publicación de actualizaciones o
              cambios en nuestra página web. Dichos cambios o implementaciones
              tendrán vigencia a partir del momento mismo en que sean publicados
              en el Sitio Web y/o App. Es su responsabilidad revisar esta página
              periódicamente para ver los cambios. Su uso de la página web o el
              acceso a ella de forma continuada después de la publicación de
              cualquier cambio constituye la aceptación de dichos cambios.
            </p>
            <p>
              Antes de poder utilizar los servicios disponibles en el Sitio Web,
              es imprescindible completar el proceso de registro y aceptar los
              términos de uso correspondientes. xumed® se esfuerza en garantizar
              que la información proporcionada en el Sitio Web sea precisa,
              apropiada y se mantenga actualizada en todo momento. Sin embargo,
              en circunstancias fuera de su control, xumed® no asume
              responsabilidad alguna.
            </p>
            <p>
              La aceptación de estos Términos y Condiciones de Uso, constituye
              al presente en un contrato válido y ejecutable. Si el Usuario no
              acepta ser obligado por los presentes Términos y Condiciones de
              Uso no podrá́ usar el Sitio web y/o App, ni acceder a los Servicios
              aquí ofrecidos.
            </p>
            <p>SECCIÓN 1: TÉRMINOS DEL SITIO WEB</p>
            <p>
              Al momento de ingresar al Sitio Web el Usuario se compromete a:
            </p>
            <p>
              No utilizar nuestros productos para ningún fin ilegal o no
              autorizado ni puede, al hacer uso del Servicio, en contradicción
              de la legislación aplicable (especialmente en materia de propiedad
              intelectual e industrial y protección de datos).
            </p>
            <p>
              Evitar la introducción de virus informáticos, gusanos, troyanos u
              otro código malicioso que pueda interferir con las funciones del
              Sitio Web.
            </p>
            <p>
              El incumplimiento o violación de cualquiera de los Términos dará
              como resultado la rescisión inmediata de sus Servicios.
            </p>
            <p>SECCIÓN 2: CONDICIONES GENERALES</p>
            <p>
              Nos reservamos el derecho de rechazar el servicio a cualquier
              persona, por cualquier motivo, en cualquier momento.
            </p>
            <p>
              Usted comprende que su contenido (sin incluir la información de la
              tarjeta de crédito) puede transferirse sin cifrar e implicar (a)
              transmisiones en varias redes; y (b) cambios para adaptarse a los
              requisitos técnicos de conexión de redes o dispositivos y cumplir
              con ellos. La información de la tarjeta de crédito siempre se
              cifra durante la transferencia a través de las redes.
            </p>
            <p>
              Usted acepta no reproducir, duplicar, copiar, vender, revender ni
              aprovechar ninguna parte del Servicio, uso del Servicio o acceso
              al Servicio o cualquier contacto en el sitio web a través de la
              cual se presta el servicio, sin nuestro permiso expreso por
              escrito.
            </p>
            <p>
              Los encabezados utilizados en este acuerdo se incluyen solo para
              facilitar la lectura y no limitarán ni afectarán los presentes
              Términos.
            </p>
            <p>SECCIÓN 3: DISPONIBILIDAD DEL SITIO WEB</p>
            <p>
              xumed® no tiene más obligación que lo establecido en los presentes
              términos de uso.
            </p>
            <p>
              En la medida de lo posible, xumed® trata de expandir y mejorar El
              Sitio Web. No obstante, El Sitio Web será mostrado al usuario tal
              y como se encuentre en cada momento, dependiendo de la
              disponibilidad del servicio y de otras limitaciones concurrentes.
              Es posible que en algún caso el acceso al Sitio Web se encuentre
              restringido.
            </p>
            <p>
              xumed® se reserva el derecho a modificar, eliminar o alterar en
              cualquier momento El Sitio Web y/o los contenidos presentes en el
              mismo, incluidas los presentes términos de uso.
            </p>
            <p>SECCIÓN 4: MODIFICACIONES AL SERVICIO Y PRECIOS</p>
            <p>
              Los precios de nuestros servicio están sujetos a cambios con
              previo aviso al usuario para su confirmación.
            </p>
            <p>
              No seremos responsables ante el usuario por ninguna modificación,
              cambio de precio, suspensión o interrupción del Servicio.
            </p>
            <p>SECCIÓN 5: ENLACES DE TERCEROS</p>
            <p>
              El Sitio web puede contener enlaces o referencias a sitios web de
              terceros. Dichos enlaces no representan ninguna aprobación de sus
              contenidos por parte de xumed.
            </p>
            <p>
              xumed no se responsabiliza de la disponibilidad o del contenido de
              tales sitios web como así tampoco de cualquier daño y perjuicio
              que pueda ocasionarse por el uso de los contenidos de terceros
              disponibles en dichos sitios web. El objeto de la incorporación de
              tales enlaces es el de facilitar el acceso a información
              disponible a través de Internet. El acceso a los enlaces es
              exclusiva responsabilidad del Usuario.
            </p>
            <p>
              Asimismo, cualquier enlace al Sitio web, de manera total o
              parcial, deberá hacerse conforme a la legislación vigente y las
              reglas de la buena fe, de modo tal que no implique competencia
              desleal, ni la lesión a los derechos de xumed, reservándose este
              último los derechos para accionar en caso contrario. Asimismo,
              xumed se reserva el derecho de inhabilitar vínculos o estructuras
              no autorizadas.
            </p>
            <p>SECCIÓN 6: INFORMACIÓN PERSONAL</p>
            <p>
              El Usuario conoce y acepta que toda información personal que se
              provea o aporte al Sitio web será́ tratada de acuerdo con la
              Política de Privacidad disponible en el Sitio web, la cual resulta
              parte integrante e inescindible de los presentes Términos y
              Condiciones de Uso.
            </p>
            <p>SECCIÓN 7: USOS PROHIBIDOS</p>
            <p>
              Además de las prohibiciones establecidas en los Términos de uso,
              se le prohíbe utilizar el sitio o su contenido (a) para cualquier
              propósito ilegal; (b) para solicitar a otros que realicen o
              participen en cualquier acto ilegal; (c) para infringir o violar
              nuestros derechos de propiedad intelectual o los derechos de
              propiedad intelectual de otros; (d) acosar, abusar, insultar,
              dañar, difamar, calumniar, denigrar, intimidar o discriminar por
              motivos de género, orientación sexual, religión, etnia, raza,
              edad, nacionalidad o discapacidad; (e) enviar información falsa o
              engañosa; (f) cargar o transmitir virus o cualquier otro tipo de
              código dañino que afecte o pueda afectar a la funcionalidad o el
              funcionamiento del Servicio o de cualquier sitio web relacionado,
              de otros sitios web o de Internet; (g) enviar correo no deseado,
              phishing, pharm, pretexto, spider, rastrear o extraer; (h) para
              cualquier propósito obsceno o inmoral; o (i) para interferir o
              eludir las funciones de seguridad del Servicio o de cualquier
              sitio web relacionado, o de otros sitios web o de Internet. Nos
              reservamos el derecho de dar por terminado su uso del Servicio o
              de cualquier sitio web relacionado por infringir cualquiera de los
              usos prohibidos.
            </p>
            <p>SECCIÓN 8: DESCARGO DE RESPONSABILIDAD DE GARANTÍAS</p>
            <p>LIMITACIÓN DE RESPONSABILIDAD</p>
            <p>
              La utilización de xumed es responsabilidad exclusiva del Usuario.
              En ningún caso, Grupo Videlsa, S.R.L, xumed, sus afiliados,
              directores, empleados, agentes o proveedores de servicios serán
              responsables por daños directos, indirectos, incidentales,
              especiales, consecuentes o punitivos, causados a un tercero por el
              uso de la plataforma, a menos que se demuestre que la
              responsabilidad total fue consecuencia de alguna negligencia de El
              Proveedor y no por mal uso del Usuario.
            </p>
            <p>
              Nos esforzamos por proporcionar un servicio ininterrumpido y libre
              de errores. Sin embargo, El Proveedor no garantiza la ausencia
              total de interrupciones o errores. En caso de interrupciones
              programadas, El Proveedor se compromete a notificar a los usuarios
              con la mayor antelación posible y a realizar esfuerzos razonables
              para minimizar cualquier inconveniente o interrupción.
            </p>
            <p>
              El Proveedor implementa medidas de seguridad para proteger los
              datos de los usuarios, pero no puede garantizar la eliminación
              completa de riesgos. En tal sentido, los usuarios se comprometen a
              colaborar en la protección de sus datos mediante prácticas
              seguras, como la gestión de contraseñas, con la obligación de no
              compartir sus claves de acceso, así como también la prevención de
              malware.
            </p>
            <p>
              El Proveedor no asume responsabilidad por decisiones médicas
              tomadas por los usuarios basados en alguna información del
              software. En ninguno de los casos, xumed sustituye el juicio
              clínico de profesionales de la salud cualificados, es decir, de El
              Usuario.
            </p>
            <p>
              xumed sólo proporciona el soporte, es decir el software a través
              de web, para que el Usuario pueda gestionar sus labores
              correspondientes. Al aceptar estos Términos y Condiciones, el
              Usuario reconoce y acepta las limitaciones de responsabilidad de
              El Proveedor.
            </p>
            <p>SECCIÓN 9: INDEMNIZACIÓN</p>
            <p>
              Si, como consecuencia de incumplimientos del usuario, xumed
              sufriera cualquier tipo de daño, perjuicio, pérdida o costo
              (incluidos honorarios de abogados y procuradores), el usuario
              estará obligado a resarcir a éste.
            </p>
            <p>
              Lo mismo respecto de reclamaciones o procedimientos iniciados por
              terceros contra xumed como consecuencia de incumplimientos del
              usuario.
            </p>
            <p>SECCIÓN 10: DIVISIBILIDAD</p>
            <p>
              En caso de que se determine que alguna disposición de los
              presentes Términos de uso sea ilegal, nula o inaplicable, dicha
              disposición será, no obstante, ejecutable en la medida en que lo
              permita la legislación aplicable, y la parte inaplicable se
              considerará separada de los presentes Términos de uso, sin que
              dicha determinación afecte a la validez y aplicabilidad de las
              demás disposiciones.
            </p>
            <p>SECCIÓN 11: RESCISIÓN</p>
            <p>
              Las obligaciones y responsabilidades de las partes incurridas
              antes de la fecha de rescisión seguirán vigentes después de la
              rescisión de este contrato a todos los efectos. Estos Términos de
              uso se encuentran vigentes a menos que usted o nosotros los
              rescindamos. Puede rescindir los presentes Términos de uso en
              cualquier momento al notificarnos que ya no desea utilizar
              nuestros Servicios o cuando cese de utilizar nuestro sitio.
            </p>
            <p>
              Si a nuestro juicio usted incumple, o sospechamos que ha
              incumplido con cualquier término o disposición de los presentes
              Términos de uso, podemos rescindir igualmente este acuerdo en
              cualquier momento sin previo aviso y usted seguirá siendo
              responsable de todos los importes adeudados, hasta la fecha de
              rescisión inclusive; y/o en consecuencia podemos denegarle el
              acceso a nuestros Servicios (o a parte de ellos).
            </p>
            <p>SECCIÓN 12: ACUERDO COMPLETO</p>
            <p>
              El hecho de que no ejerzamos o hagamos valer algún derecho o
              disposición de los presentes Términos de uso no constituirá una
              renuncia a dicho derecho o disposición.
            </p>
            <p>
              Estos Términos de servicio y cualquier política o regla de
              funcionamiento que hayamos publicado en este sitio o con respecto
              al Servicio constituye el acuerdo y el entendimiento completo
              entre usted y nosotros, y rigen su uso del Servicio, sustituyendo
              a cualquier acuerdo, comunicación o propuesta anterior o
              contemporánea, ya sea oral o escrita, entre usted y nosotros
              (incluyendo de manera enunciativa más no limitativa, las versiones
              anteriores de los Términos de uso).
            </p>
            <p>
              Cualquier ambigüedad en la interpretación de los presentes
              Términos de uso no se interpretará en contra de la parte
              redactora.
            </p>
            <p>SECCIÓN 13: LEY APLICABLE</p>
            <p>
              Los presentes Términos de uso y cualquier acuerdo por separado por
              el cual le proporcionemos Servicios se regirán e interpretarán de
              acuerdo a la legislación Dominicana y, en caso de conflicto, se
              someten a los juzgados y tribunales de La Vega, República
              Dominicana con renuncia expresa a cualquier otro fuero que pudiera
              corresponderles.
            </p>
            <p>SECCIÓN 14: CAMBIOS EN LOS TÉRMINOS DE USO</p>
            <p>
              Puede revisar la versión más reciente de los Términos de uso en
              cualquier momento en esta página.
            </p>
            <p>
              Nos reservamos el derecho de actualizar, cambiar o sustituir
              cualquier parte de los presentes Términos de uso mediante la
              publicación de actualizaciones y cambios en nuestro sitio web. El
              Proveedor notificara las nuevas actualizaciones, cambios o
              sustituciones de los presente Términos y Condiciones para el uso
              de xumed. Es su responsabilidad consultar nuestro sitio web
              periódicamente para ver los cambios. El uso que haga de nuestra
              página web o del Servicio o su acceso a cualquiera de estos de
              forma continua después de la publicación de cualquier cambio en
              los presentes Términos de uso, constituye la aceptación de dichos
              cambios.
            </p>
          </section>
          <section id="politicas">
            <h2 class="secondary--text py-5">Políticas de Privacidad</h2>
            <p>
              Se establece a continuación la forma en la que xumed® (en adelante
              “xumed”) utiliza y protege los Datos Personales suministrados por
              Usted al navegar por el Sitio Web {{domain}} (en adelante el
              “Sitio”).
            </p>
            <p>
              Al acceder al Sitio, Usted debe leer detenidamente la presente
              Política de Privacidad (en adelante la “Política”); al navegar por
              el Sitio Usted acepta íntegramente el tratamiento de la
              Información Confidencial y Datos Personales que suministrare,
              conforme se establece en la presente Política.
            </p>
            <p>Términos</p>
            <li class="mb-5">
              Usuario: quien de forma voluntaria ingresa y navega por el sitio
              web {{domain}}
            </li>
            <li class="mb-5">
              Datos Personales: información de cualquier tipo referida a
              personas físicas o jurídicas determinadas o determinables.
            </li>
            <li class="mb-5">
              Base de Datos: conjunto organizado de datos personales que sean
              objeto de tratamiento o procesamiento.
            </li>
            <li class="mb-5">
              Responsable de Base de Datos: persona física o jurídica que es
              titular de una base de datos.
            </li>
            <li class="mb-5">
              Titular de los Datos: toda persona física o jurídica, cuyos datos
              sean objeto de tratamiento.
            </li>
            <p>DERECHO DE ACCESO, CANCELACIÓN Y RECTIFICACIÓN</p>
            <p>
              Usted podrá ejercer en todo momento su derecho a: acceder,
              rectificar y suprimir su Información Personal, así como a oponerse
              al tratamiento de la misma. Al ingresar sus Datos en cualquiera de
              las secciones del sitio web, y a fin de brindarle los servicios
              ofrecidos correctamente, Usted garantiza la veracidad, exactitud,
              vigencia y autenticidad de la Información Personal suministrada.
            </p>
            <p>INFORMACIÓN RECABADA</p>
            <p>
              xumed podrá solicitar de Usted para almacenamiento y tratamiento,
              la siguiente Información Confidencial o Datos Personales: nombre,
              apellido e información física de contacto (como número de teléfono
              domicilio, dirección de e-mail, etc.). Los datos de carácter
              personal que xumed recopile serán exclusivamente utilizados para
              la consecución del objeto definido en el Sitio al momento en que
              Usted los suministrare y en observancia de lo establecido en la
              normativa sobre protección de datos personales vigente. La
              información que Usted suministrare podrá ser eliminada si así lo
              deseara en cualquier momento, una vez recibido por parte de xumed
              el pedido por escrito mediante el canal de comunicación
              establecido en la presente Política. Si se realizaran cambios
              materiales en la forma en que la Información Personal de los
              USUARIOS es almacenada y/o tratada por parte de xumed, se
              notificará al correo electrónico consignado por Usted al ingresar
              sus datos.
            </p>
            <p>CONFIDENCIALIDAD DE LA INFORMACIÓN</p>
            <p>
              Los datos recabados mediante formularios existentes en el Sitio,
              serán incorporados a una base de datos de la cual es responsable
              xumed, SRL, Sociedad constituida y existente conforme las Leyes de
              la República Dominicana.
            </p>
            <p>
              La información que Usted ingrese al Sitio es absolutamente
              confidencial y xumed protegerá la privacidad de los mismos de
              conformidad con lo dispuesto en la normativa vigente sobre
              protección de datos personales.
            </p>
            <p>SEGURIDAD Y ALMACENAMIENTO DE LA INFORMACIÓN</p>
            <p>
              xumed considera la información y los datos suministrados por Usted
              como un activo que debe ser protegido de cualquier pérdida o
              acceso no autorizado. Para ello, xumed emplea diversas técnicas de
              seguridad y protección sobre tales datos, contra accesos no
              autorizados de usuarios, ya sea dentro o fuera de nuestra
              Compañía. Sin embargo, es necesario que Usted tenga en cuenta que
              la seguridad perfecta no existe en Internet; en razón de lo cual,
              xumed no se hace responsable por interceptaciones ilegales y/o
              violación de sus sistemas o bases de datos por parte de personas
              no autorizadas.
            </p>
            <p>INDEMNIDAD</p>
            <p>
              xumed no será responsable por la consignación errónea de los datos
              por Usted ingresados que pudieran afectar derechos de terceros por
              falsedad, error y/o falta de legitimación para difundir dicha
              información.
            </p>
            <p>LEY APLICABLE</p>
            <p>
              La presente Política se interpretará conforme la normativa
              aplicable y vigente en materia de protección de datos personales
              de la República Dominicana.
            </p>
          </section>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Legal",
  data() {
    return {
      domain: "www.xumed.online",
    };
  },
};
</script>
